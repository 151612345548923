<template>
    <router-view/>
</template>

<script>
    export default {
        name    : 'Learn',
        metaInfo() {
            return {
                title: this.$t('META.ACCOUNT.TITLE'),
                meta : [
                    {
                        vmid   : 'robots',
                        name   : 'robots',
                        content: 'noindex,nofollow',
                    },
                ],
            };
        },
    };
</script>
